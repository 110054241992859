import { render, staticRenderFns } from "./CustomerDashboard.vue?vue&type=template&id=90ef63ce&scoped=true"
import script from "./CustomerDashboard.vue?vue&type=script&lang=tsx"
export * from "./CustomerDashboard.vue?vue&type=script&lang=tsx"
import style0 from "./CustomerDashboard.vue?vue&type=style&index=0&id=90ef63ce&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90ef63ce",
  null
  
)

export default component.exports