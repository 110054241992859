









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import PageTitle from "@/components/pageTitle.vue";
import AccountMultiselect from "@/components/AccountMultiselect.vue";
import LaddaButton from "../components/LaddaButton.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "../models/common";
import draggable from "vuedraggable";
import TemplateTableAddModal from "@/components/Template/TemplateTableAddModal.vue";
import moment from "moment";
const Handlebars = require("handlebars/runtime.js");

@Component({
  inheritAttrs: false,
  components: {
    AccountMultiselect,
    PageTitle,
    LaddaButton,
    ConfirmRemoveItemModal,
    draggable,
    TemplateTableAddModal
  }
})
export default class TemplateBuilder extends TSXComponent<void> {
  templateType: string = "Quote";
  templateTypeId: number = 1;
  templateView: string = "";
  templateTypesList: any[] = [
    {
      templateType: "Quote",
      typeId: 1
    },
    {
      templateType: "Invoice",
      typeId: 2
    },
    {
      templateType: "Order",
      typeId: 3
    }
  ];

  selectedElements: any[] = [
    {
      name: "CORE PRODUCTS/PRICING",
      isCoreProduct: true,
      type: "products_pricing"
    }
  ];

  draggableOptions: any[] = [
    {
      name: "contractNo",
      isContractNo: true
    },
    {
      name: "otherOptions",
      isOtherOptions: true
    }
  ];

  // default order info header columns
  orderInfoHeaderCols: any = {
    col1: [
      { key: "customerPO", option_customerPO: true },
      { key: "orderNumber", option_orderNumber: true },
      { key: "orderDate", option_orderDate: true },
      { key: "shipTo", option_shipTo: true }
    ],
    col2: [
      { key: "accountRep", option_accountRep: true },
      { key: "phone", option_phone: true },
      { key: "email", option_email: true },
      { key: "sourceRepInfo", option_sourceRepInfo: true }
    ]
  };

  templateList: any[] = [];
  quoteName: boolean = true;
  quoteNumber: boolean = true;
  quoteDate: boolean = true;
  contactEmail: boolean = true;
  contactPhone: boolean = true;
  contactRep: boolean = true;
  quoteExpDate: boolean = true;
  showTypeMenu: boolean = false;
  showStyleMenu: boolean = false;
  isEditableRemitAddress: boolean = true;
  isEditableInformationHeader: boolean = true;
  isEditablePOInstructions: boolean = true;
  isEditableQuoteNotes: boolean = true;
  isEditableDataFields: boolean = true;
  isEditableFooterDetail: boolean = true;
  compiledTemplate: any = "";
  confirmRemoveVisible: boolean = false;
  removeSection: string = "";
  CustomerInfo: boolean = false;
  RemitAddress: boolean = false;
  EditParagraph: boolean = true;
  InformationHeader: boolean = false;
  showRangeValue: number = 100;
  headerSize: number = 100;
  pricingdetails: boolean = true;
  currentTemplatePath: string = "";
  currentTemplateShort: string = "";
  templateName: string = "";
  showCreatePopup: boolean = false;
  paragraphContent: string =
    "State APP, please see last page only. All others, please make PO out to HP Inc, list Partner ID: 10281367 and Quote ID: on PO. Forward PO to AZOM@MilestoneTech.com for processing. Do not send to HP. Thank you!";
  paragraphRows: any[] = [
    {
      EDITPARAGRAPHNAME: "Purchasing Instructions",
      EDITPARAGRAPHCONTENT: this.paragraphContent
    }
  ];
  showLogo: boolean = false;
  showCustomerName: boolean = false;
  quoteDummyData: any = {
    TemplateSetting: {
      PartialPosition1: [],
      PartialPosition2: [],
      showRemitAddress: true,
      showParagarph: true,
      showInformation: true,
      showQuoteName: false,
      showQuoteNumber: false,
      showQuoteDate: false,
      showQuoteInfo: true,
      showContactInfo: true,
      showAccRep: false,
      showContactEmail: false,
      showContactPhone: false,
      companyName: "",
      streetAddress: "",
      cityState: "",
      phoneNo: "",
      quoteExpDate: false,
      headerSize: 100,
      pricingdetails: true,
      templateItemReorder: this.selectedElements,
      contactPhoneContent: "",
      contactEmailContent: "",
      footerContent: "",
      paragraphRows: this.paragraphRows,
      remitEmail: "",
      quoteNotes: "",
      showLogo: false,
      showCustomerName: false,
      showTempIndicator: false,
      draggableOptions: "",
      BuilderTemplate: true,
      quoteShippingAddr: true,
      quoteBillingAddr: true,
      // bdNumber: true,
      // sourceQuoteId: true,
      contractNumber: ""
    }
  };
  invoiceDummyData: any = {
    TemplateSetting: {
      showRemitAddress: true,
      showParagarph: true,
      showInformation: true,
      companyName: "",
      streetAddress: "",
      cityState: "",
      remitEmail: "",
      companyURL: "",
      templateItemReorder: this.selectedElements,
      pricingdetails: true,
      shipAddress: true,
      invoiceId: true,
      invDate: true,
      customerPO: true,
      footerContent: "",
      headerSize: 26,
      serialNumber: true,
      assetTag: true,
      trackingInfo: true,
      showTempLogo: true,
      dueDate: true,
      invoiceTerms: true,
      contractNumber: true,
      billAddress: true,
      draggableOptions: this.draggableOptions,
      BuilderTemplate: true,
      showLogo: false,
      showCustomerName: false,
      showTempIndicator: false
    }
  };
  orderDummyData: any = {
    TemplateSetting: {
      showRemitAddress: true,
      showParagarph: true,
      showInformation: true,
      companyName: "",
      streetAddress: "",
      cityState: "",
      phoneNo: "",
      headerSize: 26,
      contactRep: true,
      contactPhone: true,
      contactEmail: true,
      shipAddress: true,
      customerPO: true,
      orderDate: true,
      orderNO: true,
      contactPhoneContent: "",
      contactEmailContent: "",
      footerContent: "",
      pricingdetails: true,
      templateItemReorder: this.selectedElements,
      notes: true,
      notesContent: "",
      footerTerms: "",
      showLogo: false,
      showCustomerName: false,
      showTempIndicator: false,
      BuilderTemplate: true,
      draggableOptions: this.draggableOptions,
      contractNumber: ""
    },
    ORDERDETAILS: {
      CURRENTDATE: moment().format("MM/DD/YYYY")
    }
  };
  companyName: string = "";
  streetAddress: string = "";
  cityState: string = "";
  phoneNo: string = "";
  footerContent: string = "";
  quoteNotes: string = "";
  loading: boolean = false;
  isHPQuote: boolean = false;
  isQuoteContact: boolean = false;
  isQuoteExpDate: boolean = false;
  headerResize: boolean = true;
  hidePhoneNo: boolean = true;
  builderTemplate: boolean = false;
  tempTemplateId: number = 0;
  contactPhoneContent: string = "";
  contactEmailContent: string = "";
  selectedTemplateId: number = 0;
  templateDetails: any[] = [];
  paragraphIndex: number = 0;
  USERID: number = 0;
  laddaLoading: any = false;
  contactEmailError: boolean = false;
  phoneNoError: boolean = false;
  contactPhoneNoError: boolean = false;
  companyURL: string = "";
  remitEmail: string = "";
  remitEmailError: boolean = false;
  shipAddress: boolean = true;
  invoiceId: boolean = true;
  invDate: boolean = true;
  customerPO: boolean = true;
  serialNumber: boolean = true;
  assetTag: boolean = true;
  trackingInfo: boolean = true;
  orderNO: boolean = true;
  orderDate: boolean = true;
  allowOptionChanges: boolean = false;
  notes: boolean = false;
  notesContent: string = "Blind shipping required on all orders and shipments";
  hasFooterTerms: boolean = false;
  footerTerms: string = `Work under this purchase order is subject to surveillance at Seller's plant. Seller shall be notified if inspection is to be conducted on specific shipments. No shipments are to be held for inspection unless notification is received prior to, or at time of, material being ready for shipment.Seller shall maintain records of inspections, tests, and process controls called for by this purchase order. These documents shall be on file and available to Buyerfor ten (10) years following the end of the calendar year in which the purchase order was issued. At any time during the retention period, at Buyer’s request, Sellerwill deliver said records, or any part thereof, to Buyer, at no additional cost to Buyer. Certificate of Conformance may be required upon request.Please refer to Milestone Website at the following link for Quality Requirements: <a href="https://milestonetech.com/quality-requirements-and-certifications/">https://milestonetech.com/quality-requirements-and-certifications/</a> `;
  quoteDetails: any = {};
  orderTerms: string = "";
  editDefaultTemplateDetailModalVisible: boolean = false;
  checked: boolean = true;
  defaultQuoteTemplates: any = [
    "milestone id quote",
    "standard quote",
    "milestonetech quote",
    "indirect invoice",
    "arizona order"
  ];
  customerIndicators: any = ["No Indicator", "Show Logo", "Show Name"];
  selectedCustomerIndicator: string = "No Indicator";
  dueDate: boolean = true;
  invoiceTerms: boolean = true;
  contractNumber: boolean = true;
  billAddress: boolean = true;
  showTableModal: boolean = false;
  confirmTableRemove: boolean = false;
  isStandardTemplate: boolean = false;
  purchasingInstruction: boolean = false;
  footerDetail: boolean = false;
  quoteNote: boolean = false;
  dataFields: boolean = false;
  quoteShippingAddr: boolean = true;
  quoteBillingAddr: boolean = true;
  // bdNumber: boolean = true;
  // sourceQuoteId: boolean = true;
  taxMsg: string =
    "PLEASE NOTIFY US IMMEDIATELY IF THIS ORDER CANNOT BE SHIPPED COMPLETE TAX ON OR BEFORE";

  templateStyles: any = [];
  templateStyleId = 0;
  templateElements: any = [];
  currentElement: any = {};
  confirmedRemoveElement = false;
  signatures: any = [];
  selectedSignature = "";
  varInfo: any = {};

  async created() {
    const templateId = this.$route.params.id;
    if (templateId) {
      await this.fetchData();
    }
    await this.getTemplatesList(this.templateTypeId);
    this.setDefaultTemplateStyle();
    this.fetchAndRender();
  }

  async fetchData() {
    const templateId = this.$route.params.id;
    if (!templateId) {
      return;
    }

    const response = await ApiHelper.callApi("post", {
      controller: "Helpers",
      FunctionName: "TemplateDefinition",
      templateId: templateId
    });
    if (response.STATUS) {
      this.loading = false;
      const settings = response.TemplateSetting;
      this.tempTemplateId = settings.TemplateId;
      this.templateStyleId = settings.templateStyleId || 0;
      this.currentTemplatePath = settings.TemplateName.replace("../", "");
      this.currentTemplateShort = settings.TemplateName.replaceAll("/", ",")
        .split(",")
        .pop();
      this.templateTypeId = settings.TemplateType;
      this.templateName = settings.customTemplateName;
      this.companyName = settings.companyName;
      this.streetAddress = settings.streetAddress;
      this.cityState = settings.cityState;
      this.phoneNo = settings.phoneNo != undefined ? settings.phoneNo : "";
      this.RemitAddress =
        settings.showRemitAddress != undefined
          ? settings.showRemitAddress
          : true;
      this.EditParagraph =
        settings.showParagarph != undefined ? settings.showParagarph : true;
      this.InformationHeader =
        settings.showInformation != undefined ? settings.showInformation : true;
      this.pricingdetails =
        settings.pricingdetails != undefined ? settings.pricingdetails : true;
      this.showRangeValue = this.headerSize =
        settings.headerSize != undefined ? settings.headerSize * 4 : 100;
      this.hidePhoneNo =
        settings.hidePhoneNo != undefined ? settings.hidePhoneNo : true;
      this.builderTemplate = response.BuilderTemplate;
      this.footerContent =
        settings.footerContent != undefined ? settings.footerContent : "";
      this.showLogo =
        settings.showLogo != undefined ? settings.showLogo : false;
      this.showCustomerName =
        settings.showCustomerName != undefined
          ? settings.showCustomerName
          : false;

      this.isEditableInformationHeader =
        settings.editableInformationHeader != undefined
          ? settings.editableInformationHeader
          : true;
      this.isEditableRemitAddress =
        settings.editableRemitAddress != undefined
          ? settings.editableRemitAddress
          : true;
      this.isEditablePOInstructions =
        settings.editablePOInstructions != undefined
          ? settings.editablePOInstructions
          : true;
      this.isEditableQuoteNotes =
        settings.editableQuoteNotes != undefined
          ? settings.editableQuoteNotes
          : true;
      this.isEditableDataFields =
        settings.editableDataFields != undefined
          ? settings.editableDataFields
          : true;
      this.isEditableFooterDetail =
        settings.editableFooterDetail != undefined
          ? settings.editableFooterDetail
          : true;

      this.headerResize =
        settings.headerResize != undefined ? settings.headerResize : true;
      this.contractNumber = settings.contractNumber;
      this.isQuoteContact =
        settings.isQuoteContact != undefined ? settings.isQuoteContact : false;
      this.draggableOptions = settings.draggableOptions;
      this.isStandardTemplate = !this.builderTemplate;

      this.purchasingInstruction =
        settings.showPurchasingInstruction != undefined
          ? settings.showPurchasingInstruction
          : true;
      this.footerDetail =
        settings.showFooterDetail != undefined
          ? settings.showFooterDetail
          : true;
      this.quoteNote =
        settings.showQuoteNote != undefined ? settings.showQuoteNote : true;
      this.selectedElements = settings.templateItemReorder;

      if (this.showLogo) {
        this.selectedCustomerIndicator = "Show Logo";
      } else if (this.showCustomerName) {
        this.selectedCustomerIndicator = "Show Name";
      } else {
        this.selectedCustomerIndicator = "No Indicator";
      }
      if (this.templateTypeId != 2) {
        this.contactRep = settings.showAccRep;
        this.contactEmail = settings.showContactEmail;
        this.contactPhone = settings.showContactPhone;
        this.contactPhoneContent =
          settings.contactPhoneContent != undefined
            ? settings.contactPhoneContent
            : "";
        this.contactEmailContent =
          settings.contactEmailContent != undefined
            ? settings.contactEmailContent
            : "";
      }
      if (this.templateTypeId == 1) {
        this.quoteName = settings.showQuoteName;
        this.quoteNumber = settings.showQuoteNumber;
        this.quoteDate = settings.showQuoteDate;
        this.isHPQuote =
          settings.isHPQuote != undefined ? settings.isHPQuote : false;
        this.isQuoteExpDate =
          settings.isQuoteExpDate != undefined
            ? settings.isQuoteExpDate
            : false;
        this.quoteExpDate = settings.quoteExpDate;
        this.paragraphRows =
          this.$route.params.id && settings.paragraphRows.length
            ? settings.paragraphRows
            : [
                {
                  EDITPARAGRAPHNAME: "Purchasing Instruction",
                  EDITPARAGRAPHCONTENT:
                    "State APP, please see last page only. All others, please make PO out to HP Inc, list Partner ID: 10281367 and Quote ID: on PO. Forward PO to AZOM@MilestoneTech.com for processing. Do not send to HP. Thank you!"
                }
              ];
        this.selectedTemplateId =
          settings.selectedTemplateId != undefined
            ? settings.selectedTemplateId
            : 0;
        this.remitEmail =
          settings.remitEmail != undefined ? settings.remitEmail : "";
        this.quoteDetails =
          response.QUOTEDETAILS != undefined ? response.QUOTEDETAILS : {};
        this.templateDetails = settings.QuoteTemplates;
        this.quoteShippingAddr = settings.quoteShippingAddr;
        this.quoteBillingAddr = settings.quoteBillingAddr;
        // this.bdNumber = settings.bdNumber;
        // this.sourceQuoteId = settings.sourceQuoteId;
        if (settings.quoteNotes != undefined)
          this.quoteNotes = settings.quoteNotes;
      }
      if (this.templateTypeId != 1) {
        this.shipAddress =
          settings.shipAddress != undefined ? settings.shipAddress : true;
        this.customerPO =
          settings.customerPO != undefined ? settings.customerPO : true;
      }
      if (this.templateTypeId == 2) {
        this.remitEmail =
          settings.remitEmail != undefined ? settings.remitEmail : "";
        this.companyURL =
          settings.companyURL != undefined ? settings.companyURL : "";
        this.invoiceId =
          settings.invoiceId != undefined ? settings.invoiceId : true;
        this.invDate = settings.invDate != undefined ? settings.invDate : true;
        this.serialNumber =
          settings.serialNumber != undefined ? settings.serialNumber : true;
        this.assetTag =
          settings.assetTag != undefined ? settings.assetTag : true;
        this.trackingInfo =
          settings.trackingInfo != undefined ? settings.trackingInfo : true;
        this.dueDate = settings.dueDate;
        this.invoiceTerms = settings.invoiceTerms;
        this.billAddress = settings.billAddress;
      }
      if (this.templateTypeId == 3) {
        this.allowOptionChanges = settings.allowOptionChanges;
        this.hasFooterTerms = settings.hasFooterTerms;
        this.orderDate = settings.orderDate;
        this.orderNO = settings.orderNO;
        this.taxMsg = settings.taxMsg || "";
        this.footerTerms =
          settings.footerTerms != undefined
            ? settings.footerTerms
            : this.footerTerms;
      }
      if (this.templateTypeId == 3 && this.allowOptionChanges) {
        this.notes = settings.notes;
        this.notesContent =
          settings.notesContent != ""
            ? settings.notesContent
            : this.notesContent;
      }
    }

    if (this.isOrder) {
      const infoHeader = this.selectedElements.find(
        item => item.type == "information_header"
      );
      if (infoHeader) {
        if (infoHeader.cols) {
          this.orderInfoHeaderCols = infoHeader.cols;
        } else {
          // default
          infoHeader.cols = this.orderInfoHeaderCols;
        }
      }
    }

    for (const item of this.selectedElements) {
      if (item.isCoreProduct || false) {
        item.type = "products_pricing";
      }
      switch (item.type) {
        case "footer_details":
          if (this.isOrder) {
            this.selectedSignature = item.signature || "";
          }
          break;
      }
    }
  }

  async getTemplatesList(typeId) {
    this.showTypeMenu = false;
    const response = await ApiHelper.callApi("post", {
      controller: "Templates",
      FunctionName: "List",
      subsystem: "VAR360",
      getAll: 1,
      templatetype: typeId,
      fromTemplateBuilder: 1,
      getTemplateStyles: 1
    });

    this.templateStyles = response.templateStyles || [];
    this.templateElements = (response.templateElements || []).map(item => {
      const el: any = {
        name: item.ELEMENTNAME.toUpperCase(),
        type: item.ELEMENTTYPE || "",
        uniqueInTemplate: item.UNIQUEINTEMPLATE || 0
      };

      // change later
      switch (el.type) {
        case "remit_address":
          el.isRemitAddress = true;
          break;
        case "information_header":
          el.isInfoHeader = true;
          el.icon = "terms-icon";
          break;
        case "data_fields":
          el.isDataFields = true;
          el.icon = "information-lines-icon";
          break;
        case "quote_notes":
          el.isQuoteNote = true;
          break;
        case "purchasing_instructions":
          el.isPurchasing = true;
          break;
        case "footer_details":
          el.isFooterDetail = true;
          break;
      }

      return el;
    });
    this.signatures = response.signatures || [];
    this.varInfo = response.varInfo || {};
  }

  listPageRedirection() {
    this.$router.push({ name: "EditTemplate" });
  }

  fetchAndRender() {
    if (!this.currentTemplatePath) {
      return;
    }

    if (this.templateTypeId == 1) {
      // tmp fix for notes
      if (this.quoteNote && this.quoteNotes) {
        this.quoteDetails.DISPLAYQUOTENOTES = true;
      } else {
        this.quoteDetails.DISPLAYQUOTENOTES = false;
      }

      this.quoteDummyData = {
        TemplateSetting: {
          PartialPosition1: [
            {
              partialname: "quoteDetails_notes"
            },
            {
              partialname: "quoteDetails_purchasing"
            }
          ],
          PartialPosition2: [],
          showQuoteName: this.quoteName,
          showQuoteNumber: this.quoteNumber,
          showQuoteDate: this.quoteDate,
          showQuoteInfo: true,
          showContactInfo: true,
          showAccRep: this.contactRep,
          showContactEmail: this.contactEmail,
          showContactPhone: this.contactPhone,
          companyName: this.companyName,
          streetAddress: this.streetAddress,
          cityState: this.cityState,
          phoneNo: this.phoneNo,
          quoteExpDate: this.quoteExpDate,
          showRemitAddress: this.RemitAddress,
          showParagarph: this.EditParagraph,
          showInformation: this.InformationHeader,
          headerSize: this.headerSize / 4,
          pricingdetails: true,
          templateItemReorder: this.selectedElements,
          contactPhoneContent: this.contactPhoneContent,
          contactEmailContent: this.contactEmailContent,
          footerContent: this.footerContent,
          paragraphRows: this.paragraphRows,
          remitEmail: this.remitEmail,
          quoteNotes: this.quoteNotes,
          showLogo: false,
          showCustomerName: false,
          showTempIndicator: false,
          draggableOptions: this.draggableOptions,
          BuilderTemplate: true,
          contractNumber: this.contractNumber,
          showPurchasingInstruction: this.purchasingInstruction,
          showFooterDetail: this.footerDetail,
          showQuoteNote: this.quoteNote,
          quoteShippingAddr: this.quoteShippingAddr,
          quoteBillingAddr: this.quoteBillingAddr,
          // bdNumber: this.bdNumber,
          // dummyBdNumber: this.bdNumber,
          // sourceQuoteId: this.sourceQuoteId,
          // dummySourceQuoteId: this.sourceQuoteId,
          quoteDetailNotes: true,
          quoteDetailsPurchasing: true
        },
        splitBySubQuoteId: ["DummyArr"],
        USERBILLINGINFO: this.quoteBillingAddr ? ["1"] : [],
        USERSHIPPINGINFO: this.quoteShippingAddr ? ["1"] : [],
        QUOTEDETAILS: this.quoteDetails,
        varInfo: this.varInfo
      };
      if (this.selectedCustomerIndicator == "Show Logo") {
        this.quoteDummyData.TemplateSetting.showTempIndicator = true;
        this.showLogo = this.quoteDummyData.TemplateSetting.showLogo = true;
        this.showCustomerName = false;
      } else if (this.selectedCustomerIndicator == "Show Name") {
        this.quoteDummyData.TemplateSetting.showTempIndicator = true;
        this.showCustomerName =
          this.quoteDummyData.TemplateSetting.showCustomerName = true;
        this.showLogo = false;
      } else {
        this.showCustomerName = false;
        this.showLogo = false;
      }
      if (
        !this.quoteDummyData.TemplateSetting.showQuoteName &&
        !this.quoteDummyData.TemplateSetting.showQuoteNumber &&
        !this.quoteDummyData.TemplateSetting.showQuoteDate
      ) {
        this.quoteDummyData.TemplateSetting.showQuoteInfo = false;
      }
      if (
        !this.quoteDummyData.TemplateSetting.showAccRep &&
        !this.quoteDummyData.TemplateSetting.showContactEmail &&
        !this.quoteDummyData.TemplateSetting.showContactPhone
      ) {
        this.quoteDummyData.TemplateSetting.showContactInfo = false;
      }
      const tempPosition1 =
        this.quoteDummyData.TemplateSetting.PartialPosition1 || [];
      const tempPosition2 =
        this.quoteDummyData.TemplateSetting.PartialPosition2 || [];
      if (tempPosition1.length > 0) {
        tempPosition1.forEach(item => {
          var quoteDetails_position1 = require(`../templates/quotes/partials/${item.partialname}.handlebars`);
          Handlebars.registerPartial(item.partialname, quoteDetails_position1);
        });
      }
      if (tempPosition2.length > 0) {
        tempPosition2.forEach(item => {
          var quoteDetails_position2 = require(`../templates/quotes/partials/${item.partialname}.handlebars`);
          Handlebars.registerPartial(item.partialname, quoteDetails_position2);
        });
      }
      const template = require("../" + this.currentTemplatePath);
      this.compiledTemplate = template(this.quoteDummyData);
    }

    if (this.templateTypeId == 2) {
      this.invoiceDummyData.varInfo = this.varInfo;
      this.invoiceDummyData.TemplateSetting = {
        showRemitAddress: this.RemitAddress,
        showParagarph: this.EditParagraph,
        showInformation: this.InformationHeader,
        companyName: this.companyName,
        streetAddress: this.streetAddress,
        cityState: this.cityState,
        remitEmail: this.remitEmail,
        companyURL: this.companyURL,
        templateItemReorder: this.selectedElements,
        pricingdetails: true,
        shipAddress: this.shipAddress,
        invoiceId: this.invoiceId,
        invDate: this.invDate,
        customerPO: this.customerPO,
        footerContent: this.footerContent,
        headerSize: this.headerSize / 4,
        serialNumber: this.serialNumber,
        assetTag: this.assetTag,
        trackingInfo: this.trackingInfo,
        showTempLogo: this.showLogo,
        dueDate: this.dueDate,
        invoiceTerms: this.invoiceTerms,
        contractNumber: this.contractNumber,
        billAddress: this.billAddress,
        draggableOptions: this.draggableOptions,
        BuilderTemplate: true,
        showLogo: false,
        showCustomerName: false,
        showTempIndicator: false,
        showFooterDetail: this.footerDetail
      };
      if (this.selectedCustomerIndicator == "Show Logo") {
        this.invoiceDummyData.TemplateSetting.showTempIndicator = true;
        this.showLogo = this.invoiceDummyData.TemplateSetting.showLogo = true;
        this.showCustomerName = false;
      } else if (this.selectedCustomerIndicator == "Show Name") {
        this.invoiceDummyData.TemplateSetting.showTempIndicator = true;
        this.showCustomerName =
          this.invoiceDummyData.TemplateSetting.showCustomerName = true;
        this.showLogo = false;
      } else {
        this.showCustomerName = false;
        this.showLogo = false;
      }
      const template = require("../" + this.currentTemplatePath);
      this.compiledTemplate = template(this.invoiceDummyData);
    }

    if (this.isOrder) {
      // update elements
      const dataFields = this.selectedElements.find(
        item => item.type == "data_fields"
      );
      if (dataFields) {
        dataFields.showEUs =
          dataFields.euName ||
          dataFields.euEmail ||
          dataFields.euPhone ||
          false;
        if (dataFields.showEUs) {
          // dummy data for end users
          this.orderDummyData.orderEUs = [
            {
              EUNAME: "[EU Name...]",
              EMAIL: "[EU Email...]",
              PHONE: "[EU Phone...]"
            }
          ];
        }
      }

      // information header
      const infoHeader = this.selectedElements.find(
        item => item.type == "information_header"
      );
      if (infoHeader) {
        if (infoHeader.sourceRepInfo) {
          // source rep and info
          this.orderDummyData.ORDERDETAILS.VARSOURCE_NAME = "[SOURCE]";
        }
      }

      this.orderDummyData.varInfo = this.varInfo;
      this.orderDummyData.TemplateSetting = {
        showRemitAddress: this.RemitAddress,
        showParagarph: this.EditParagraph,
        showInformation: this.InformationHeader,
        companyName: this.companyName,
        streetAddress: this.streetAddress,
        cityState: this.cityState,
        phoneNo: this.phoneNo,
        headerSize: this.headerSize / 4,
        showAccRep: this.contactRep,
        showContactEmail: this.contactEmail,
        showContactPhone: this.contactPhone,
        shipAddress: this.shipAddress,
        customerPO: this.customerPO,
        orderDate: this.orderDate,
        orderNO: this.orderNO,
        contactPhoneContent: this.contactPhoneContent,
        contactEmailContent: this.contactEmailContent,
        footerContent: this.footerContent,
        pricingdetails: true,
        templateItemReorder: this.selectedElements,
        notes: this.notes,
        notesContent: this.notesContent,
        footerTerms: this.footerTerms,
        showLogo: false,
        showCustomerName: false,
        showTempIndicator: false,
        BuilderTemplate: true,
        draggableOptions: this.draggableOptions,
        contractNumber: this.contractNumber,
        isStandardTemplate: false,
        showFooterDetail: this.footerDetail,
        taxMsg: this.taxMsg,
        sourceRepInfo: infoHeader && infoHeader.sourceRepInfo
      };
      if (this.selectedCustomerIndicator == "Show Logo") {
        this.orderDummyData.TemplateSetting.showTempIndicator = true;
        this.showLogo = this.orderDummyData.TemplateSetting.showLogo = true;
        this.showCustomerName = false;
      } else if (this.selectedCustomerIndicator == "Show Name") {
        this.orderDummyData.TemplateSetting.showTempIndicator = true;
        this.showCustomerName =
          this.orderDummyData.TemplateSetting.showCustomerName = true;
        this.showLogo = false;
      } else {
        this.showCustomerName = false;
        this.showLogo = false;
      }

      const template = require("../" + this.currentTemplatePath);
      this.compiledTemplate = template(this.orderDummyData);
    }
  }

  closeTemplateType() {
    this.showTypeMenu = false;
  }

  closeTemplateStyle() {
    this.showStyleMenu = false;
  }

  getRangeValue() {
    this.showRangeValue = this.headerSize;
    this.fetchAndRender();
  }

  async saveTemplate() {
    if (
      !this.builderTemplate &&
      parseInt(this.$route.params.id) == this.tempTemplateId
    ) {
      return;
    }

    let valid = await this.$validator.validateAll();
    if (!valid) {
      return;
    }
    let emailRegex =
      /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (this.templateTypeId != 2) {
      if (
        this.contactEmailContent.length &&
        !this.contactEmailContent.match(emailRegex)
      ) {
        this.contactEmailError = true;
        return;
      }
      if (this.phoneNo.length && this.phoneNo.match(/[^0-9]+$/)) {
        this.phoneNoError = true;
        return;
      }
      if (
        this.contactPhoneContent.length &&
        this.contactPhoneContent.match(/[^0-9]+$/)
      ) {
        this.contactPhoneNoError = true;
        return;
      }
    }
    if ([1, 2].includes(this.templateTypeId)) {
      if (this.remitEmail.length && !this.remitEmail.match(emailRegex)) {
        this.remitEmailError = true;
        return;
      }
    }

    // correct datapoint on each element
    for (const element of this.selectedElements) {
      // template type
      element.templateType = this.templateTypeId;
      // edittable
      switch (element.type) {
        case "footer_details":
          element.editable = this.isEditableFooterDetail;
          if (this.isOrder) {
            // collect signature
            element.signature = this.selectedSignature;
          }
          break;
        case "remit_address":
          element.editable = this.isEditableRemitAddress;
          break;
        case "information_header":
          element.editable = this.isEditableInformationHeader;
          if (this.isOrder) {
            element.cols = this.orderInfoHeaderCols;
          }
          break;
        case "purchasing_instructions":
          element.editable = this.isEditablePOInstructions;
          break;
        case "data_fields":
          element.editable = this.isEditableDataFields;
          // correct data for quote/invoice
          if (this.isQuote) {
            element.quoteShippingAddr = this.quoteShippingAddr || false;
            element.quoteBillingAddr = this.quoteBillingAddr || false;
          } else if (this.isInvoice) {
            element.serialNumber = this.serialNumber || false;
            element.assetTag = this.assetTag || false;
            element.trackingInfo = this.trackingInfo || false;
          } else if (this.isOrder) {
            element.showEUs =
              element.euName || element.euEmail || element.euPhone || false;
          }
          break;
        case "quote_notes":
          element.editable = this.isEditableQuoteNotes;
          break;
      }
      element[`type_${element.type}`] = true;
    }

    let templateSettings: any = {};
    templateSettings.companyName = this.companyName;
    templateSettings.streetAddress = this.streetAddress;
    templateSettings.cityState = this.cityState;
    templateSettings.headerSize = this.headerSize / 4;
    templateSettings.showRemitAddress = this.RemitAddress;
    templateSettings.showParagarph = this.EditParagraph;
    templateSettings.showInformation = this.InformationHeader;
    templateSettings.pricingdetails = true;
    templateSettings.templateItemReorder = this.selectedElements;
    templateSettings.showLogo = this.showLogo;
    templateSettings.showCustomerName = this.showCustomerName;
    templateSettings.contractNumber = this.contractNumber;
    templateSettings.draggableOptions = this.draggableOptions;
    templateSettings.editableSections = {
      editableRemitAddress: this.isEditableRemitAddress,
      editableInformationHeader: this.isEditableInformationHeader,
      editablePOInstructions: this.isEditablePOInstructions,
      editableQuoteNotes: this.isEditableQuoteNotes,
      editableDataFields: this.isEditableDataFields,
      editableFooterDetail: this.isEditableFooterDetail
    };
    templateSettings.footerContent = this.footerContent;
    templateSettings.showPurchasingInstruction = this.purchasingInstruction;
    templateSettings.showFooterDetail = this.footerDetail;
    templateSettings.showQuoteNote = this.quoteNote;
    if (this.templateTypeId != 2) {
      templateSettings.showAccRep = this.contactRep;
      templateSettings.showContactEmail = this.contactEmail;
      templateSettings.showContactPhone = this.contactPhone;
      templateSettings.phoneNo = this.phoneNo;
      templateSettings.contactPhoneContent = this.contactPhoneContent;
      templateSettings.contactEmailContent = this.contactEmailContent;
    }
    if (this.templateTypeId == 1) {
      templateSettings.showQuoteName = this.quoteName;
      templateSettings.showQuoteNumber = this.quoteNumber;
      templateSettings.showQuoteDate = this.quoteDate;
      templateSettings.showQuoteInfo =
        this.quoteDummyData.TemplateSetting.showContactInfo;
      templateSettings.showContactInfo =
        this.quoteDummyData.TemplateSetting.showQuoteInfo;
      templateSettings.quoteExpDate = this.quoteExpDate;
      templateSettings.isHPQuote = this.isHPQuote;
      templateSettings.isQuoteContact = this.isQuoteContact;
      templateSettings.isQuoteExpDate = this.isQuoteExpDate;
      templateSettings.headerResize = this.headerResize;
      templateSettings.hidePhoneNo = this.hidePhoneNo;
      templateSettings.contactPhoneContent = this.contactPhoneContent;
      templateSettings.contactEmailContent = this.contactEmailContent;
      templateSettings.selectedTemplateId = this.tempTemplateId;
      templateSettings.paragraphRows = this.paragraphRows;
      templateSettings.quoteNotes = this.quoteNotes;
      templateSettings.remitEmail = this.remitEmail;
      templateSettings.QuoteTemplateName = this.templateDetails;
      templateSettings.quoteShippingAddr = this.quoteShippingAddr;
      templateSettings.quoteBillingAddr = this.quoteBillingAddr;
      // templateSettings.bdNumber = this.bdNumber;
      // templateSettings.sourceQuoteId = this.sourceQuoteId;
    }
    if (this.templateTypeId != 1) {
      templateSettings.shipAddress = this.shipAddress;
      templateSettings.customerPO = this.customerPO;
    }
    if (this.templateTypeId == 2) {
      templateSettings.selectedTemplateId = this.tempTemplateId;
      templateSettings.remitEmail = this.remitEmail;
      templateSettings.companyURL = this.companyURL;
      templateSettings.invoiceId = this.invoiceId;
      templateSettings.invDate = this.invDate;
      templateSettings.serialNumber = this.serialNumber;
      templateSettings.assetTag = this.assetTag;
      templateSettings.trackingInfo = this.trackingInfo;
      templateSettings.dueDate = this.dueDate;
      templateSettings.invoiceTerms = this.invoiceTerms;
      templateSettings.billAddress = this.billAddress;
    }
    if (this.templateTypeId == 3) {
      templateSettings.orderDate = this.orderDate;
      templateSettings.orderNO = this.orderNO;
      templateSettings.allowOptionChanges = this.allowOptionChanges;
      templateSettings.footerTerms = this.footerTerms;
      templateSettings.hasFooterTerms = this.hasFooterTerms;
      templateSettings.isStandardTemplate = this.isStandardTemplate;
      templateSettings.taxMsg = this.taxMsg;
    }
    if (this.templateTypeId == 3 && this.allowOptionChanges) {
      templateSettings.notes = this.notes;
      templateSettings.notesContent = this.notesContent;
    }

    templateSettings.templateStyleId = this.templateStyleId || 0;

    this.laddaLoading = true;
    const response = await ApiHelper.callApi("post", {
      controller: "Templates",
      FunctionName: "Update",
      subsystem: "VAR360",
      templateName: this.templateName,
      templateLocation: this.templateLocation,
      templateID: this.$route.params.id ? this.$route.params.id : 0,
      templateType: this.templateTypeId,
      newBuilderTemplate: this.$route.params.id ? false : true,
      customTemplateSettings: templateSettings
    });
    if (response.STATUS) {
      notifier.success(response.STATUSMESSAGE);
      this.laddaLoading = false;
      this.$router.push({
        name: "EditTemplate"
      });
    } else {
      this.laddaLoading = "error";
      notifier.alert(response.STATUSMESSAGE);
    }
  }

  addRow() {
    this.paragraphRows.push({
      EDITPARAGRAPHNAME: "",
      EDITPARAGRAPHCONTENT: ""
    });
  }

  addTable(tableDetails, elementId) {
    if (!elementId) {
      this.addElement({
        name: "TABLE CONTENT",
        type: "table",
        title: "",
        editable: true,
        value: tableDetails
      });
    } else {
      // update current element
      const inList = this.selectedElements.find(item => item.id == elementId);
      if (inList) {
        inList.value = tableDetails;
      }
    }
    this.showTableModal = false;
  }

  editTable(element) {
    this.currentElement = element;
    this.showTableModal = true;
  }

  removeTable(tableSection, index) {
    this.confirmTableRemove = false;
    tableSection.splice(index, 1);
    this.fetchAndRender();
  }

  removeRow(index) {
    this.paragraphRows.splice(index, 1);
    this.fetchAndRender();
  }

  clickOutside() {
    this.showCreatePopup = false;
  }

  async loadTemplateStyle(item) {
    this.showStyleMenu = false;
    this.templateStyleId = item.TEMPLATESTYLEID;
    this.currentTemplatePath = item.TEMPLATEFILE.replace("../", "");
    this.fetchAndRender();
  }

  get templateStyleName() {
    let ret = "";
    if (this.templateStyleId) {
      const inList = this.templateStyles.find(
        item => item.TEMPLATESTYLEID == this.templateStyleId
      );
      if (inList) {
        ret = inList.TEMPLATESTYPENAME;
      }
    }

    return ret;
  }

  get templateTypeName() {
    let ret = "";
    if (this.templateTypeId) {
      const inList = this.templateTypesList.find(
        item => item.typeId == this.templateTypeId
      );
      if (inList) {
        ret = inList.templateType;
      }
    }

    return ret;
  }

  getElementIcon(icon) {
    if (!icon) return "";

    try {
      return require(`@/assets/svg/${icon}.svg`);
    } catch (err) {
      // do nothing
    }

    return "";
  }

  addElement(element) {
    if (!element.type) return;

    const uniqueInTemplate = element.uniqueInTemplate || 0;
    const inList = this.selectedElements.find(
      item => item.type == element.type
    );
    if (uniqueInTemplate && inList) {
      // ignore adding more if just allow adding once for this element
      return;
    }

    // change later
    switch (element.type) {
      case "footer_details":
        this.footerDetail = true;
        element.editable = this.isEditableFooterDetail;
        break;
      case "remit_address":
        this.RemitAddress = true;
        this.CustomerInfo = true;
        element.editable = this.isEditableRemitAddress;
        break;
      case "information_header":
        this.InformationHeader = true;
        element.editable = this.isEditableInformationHeader;
        if (this.isOrder) {
          element.cols = this.orderInfoHeaderCols;
        }
        break;
      case "purchasing_instructions":
        this.purchasingInstruction = true;
        element.editable = this.isEditablePOInstructions;
        break;
      case "data_fields":
        this.dataFields = true;
        element.editable = this.isEditableDataFields;
        break;
      case "quote_notes":
        this.quoteNote = true;
        element.editable = this.isEditableQuoteNotes;
        break;
      default:
        element.editable = true;
        break;
    }

    // add element to the list
    element.id = this.generateElementId(element.type);
    element[`type_${element.type}`] = true;
    this.selectedElements.push(element);
    for (const item of this.selectedElements) {
      item.templateType = this.templateTypeId;
    }
    this.fetchAndRender();
  }

  generateElementId(type) {
    if (!type) return "";

    const ids: number[] = this.selectedElements
      .filter(item => (item.type || "") == type)
      .map(item => parseInt((item.id || "").replace(`${type}_`, "")));
    if (!ids.length) {
      return `${type}_0`;
    } else {
      const max = Math.max.apply(Math, ids);
      return `${type}_${max + 1}`;
    }
  }

  removeElement(element, force = false) {
    if (!this.confirmRemoveVisible && !force) {
      this.currentElement = element;
      this.removeSection = `${element.name}`.toUpperCase();
      this.confirmRemoveVisible = true;
      return;
    }

    if (element.type) {
      switch (element.type) {
        case "footer_details":
          this.footerDetail = false;
          this.footerContent = "";
          break;
        case "remit_address":
          this.RemitAddress = false;
          this.CustomerInfo = false;
          this.selectedCustomerIndicator = "No Indicator";
          this.showRangeValue = 100;
          break;
        case "information_header":
          this.InformationHeader = false;
          break;
        case "purchasing_instructions":
          this.purchasingInstruction = false;
          this.paragraphRows = [
            {
              EDITPARAGRAPHNAME: "Purchasing Instructions",
              EDITPARAGRAPHCONTENT: this.paragraphContent
            }
          ];
          break;
        case "data_fields":
          this.dataFields = false;
          this.isEditableDataFields = true; // default
          break;
        case "quote_notes":
          this.quoteNote = false;
          this.quoteNotes = "";
          break;
      }

      if (element.id) {
        this.selectedElements = this.selectedElements.filter(
          item => (item.id || "") != element.id
        );
      } else {
        this.selectedElements = this.selectedElements.filter(
          item => (item.type || "") != element.type
        );
      }
    }

    this.confirmRemoveVisible = false;
    this.fetchAndRender();
  }

  async templateTypeChange(typeItem) {
    this.templateTypeId = typeItem.typeId;
    await this.getTemplatesList(typeItem.typeId);
    this.setDefaultTemplateStyle();
    // correct data for elements
    for (const item of this.selectedElements) {
      item.templateType = this.templateTypeId;

      if (item.type == "information_header") {
        if (this.isOrder) {
          item.cols = this.orderInfoHeaderCols;
        } else {
          delete item.cols;
        }
      }
    }
    this.fetchAndRender();
  }

  setDefaultTemplateStyle() {
    let defaultStyle: any = {};
    if (this.templateStyleId) {
      const inList = this.templateStyles.find(
        item => item.TEMPLATESTYLEID == this.templateStyleId
      );
      if (inList) {
        defaultStyle = inList;
      }
    }

    if (!defaultStyle.TEMPLATESTYLEID && this.templateStyles.length) {
      defaultStyle = this.templateStyles[0];
    }

    if (defaultStyle.TEMPLATESTYLEID) {
      this.templateStyleId = defaultStyle.TEMPLATESTYLEID;
      this.currentTemplatePath = defaultStyle.TEMPLATEFILE.replace("../", "");
    }
  }

  get templateLocation() {
    // base on selected template style
    let ret = "";
    if (this.templateStyleId && this.templateStyles.length) {
      const inList = this.templateStyles.find(
        item => item.TEMPLATESTYLEID == this.templateStyleId
      );
      if (inList) {
        ret = inList.TEMPLATEFILE || "";
      }
    }

    return ret;
  }

  get pageTitleStr() {
    return this.$route.params.id
      ? `Edit #${this.templateName}`
      : "Template Builder";
  }

  get isQuote() {
    return this.templateTypeId == 1;
  }

  get isOrder() {
    return this.templateTypeId == 3;
  }

  get isInvoice() {
    return this.templateTypeId == 2;
  }
}
