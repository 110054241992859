































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "../LaddaButton.vue";
import { ApiHelper } from "@/helpers/all";
import Datepicker from "@/components/Datepicker.vue";
import moment from "moment";
import {dollarFormat} from "../../helpers/ApiHelper";

declare const dataURL: string;
declare const $: any;

interface Props {
  data: any;
  title: string;
}

@Component({
  methods: {dollarFormat},
  inheritAttrs: false,
  components: {
    LaddaButton,
    Datepicker
  }
})
export default class ModalSelectInvoices extends TSXComponent<void> {
  @Prop({ required: true, default: [] })
  data!: any;

  @Prop({ required: true, default: '' })
  title!: string;

  @Prop({ required: true, default: 0 })
  purchaseID!: number

  selectedInvoiceIds: any[] = [];

  saving: boolean | "error" = false;
  showField = false;
  $parent: any;
  sourceOrderNumber: string = "";

  $refs!: {
    modal: HTMLDivElement;
  };

  defaultInvoice: any = null;
  defaultInvoiceId = '';
  created() {
    this.defaultInvoice = this.data.invoicesBelongTo.find((item) => dollarFormat(this.data.ORDERPAYMENTTOTAL) === dollarFormat(item.ITOTALPRICE));
    if(this.defaultInvoice) {
      this.defaultInvoiceId = this.defaultInvoice.INVOICEID;
      this.selectedInvoiceIds = [this.defaultInvoiceId];
    }
  }

  mounted() {
  }

  beforeDestroy() {
  }

  async onSave() {
    this.saving = false;
    this.$emit('callback', this.selectedInvoiceIds);
  }
}
