


















































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import AddIdentifiers from "@/components/Order/AddIdentifiersModal.vue";
import ContentToolTip from "@/components/Common/ContentToolTip.vue";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {
    ContentToolTip,
    AddIdentifiers
  }
})
export default class OrderHardwares extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: [] })
  items?: any;

  @Prop({ required: false, default: [] })
  hardwareInfo?: any;

  @Prop({ required: false, default: "grouped" })
  hardwareView!: string;

  @Prop({ required: false, default: [] })
  itemDetails!: any;

  @Prop({ required: false, default: false })
  isLoading!: boolean;
    
  productLine = [];

  hardwareItemTooltipIndex = -1;

  @Watch("items")
  itemsChanged(newItems: any) {
    if (this.hardwareInfo.length) {
      for (const item of newItems) {
        item.hardwares = this.hardwareInfo.filter(
          t =>
            t.HARDWARESKU == item.HARDWARESKU &&
            (t.ASSETNUMBER != "" || t.ASSETTAG != "")
        );
      }
    }
  }

  $allowedFunctions: any;
  $userFunctions: any;
  $parent: any;
  addIdentifiersModalVisible = false;
  identifierData: any = {};
  hardwareDetails: any = [];
  hardwareQuantity = 0;
  currentRole = sessionStorage.getItem("userRole");
  hardwaresLoading = false;

  getHardwareSerialNumber(serial = "", tag = "") {
    const ret: string[] = [];
    if (serial != "") {
      ret.push(serial);
    }
    if (tag != "") {
      ret.push(tag);
    }

    return ret.join("/");
  }

  async addIdentifier(item) {
    this.addIdentifiersModalVisible = true;
    this.identifierData = item;
    this.hardwareQuantity = item.QUANTITY;
    let hardwareDetails = this.hardwareInfo.filter(
      t => t.HARDWARESKU == item.HARDWARESKU
    );
    this.hardwareDetails = JSON.parse(JSON.stringify(hardwareDetails));
  }

  async reloadOrder(sku = "") {
    try {
      this.hardwaresLoading = true;
      await this.$parent.getOrdersdetails();
      // reload hardware items of this sku
      if (sku != "") {
        this.identifierData = (this.$parent.hardwaredetails || []).find(
          item => item.HARDWARESKU == sku
        );
        const hardwareDetails = this.hardwareInfo.filter(
          t => t.HARDWARESKU == sku
        );
        this.hardwareDetails = JSON.parse(JSON.stringify(hardwareDetails));
        this.hardwareQuantity = hardwareDetails.length;
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.hardwaresLoading = false;
    }
  }

  async searchBy(field) {
    await this.$parent.changeHardwareView(this.hardwareView, true);
  }

  async resetSearchBy(field) {
    if (field in this.$parent.hardwareFilters) {
      this.$parent.hardwareFilters[field] = "";
      await this.$parent.changeHardwareView(this.hardwareView, true);
    }
  }
}
